.cards-container{
    padding: 0 2rem 2rem 2rem;
    margin: 2rem 0 2rem 0;
}

.card-container{
    background-color: lightgrey;
    box-shadow: 0px 0px 15px -5px;
    border-radius: 6px;
    padding: 2rem 2rem 2rem 2rem;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
}

.image-container img{
    overflow: hidden;
    height: 100px;
}

.card-content{
    margin: 1rem;
    margin-top: 0.5rem;
}

h3,p{
    margin: 0;
    pad: 0;
}

.card-title{
    margin: 1rem 0 0.5rem;
}